* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}



html,
body {
    font-family: 'Muli', sans-serif;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #444;
    line-height: 1.5;

    text-rendering: optimizeLegibility;
    -webkit-font-smoobox: antialiased;
    -webkit-text-size-adjust: none;
}

body {
    padding: 3em 0;
}


.center {
    text-align: center;
}

.right {
    text-align: right;
}



/*
    Grid Setup
 */

.row {
    width: 1200px;
    margin: 0 auto;
}

.row .row {
    max-width: 100%;
}



/*
    Images
 */
img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
}

.rebox img {
    width: auto;
    margin: 0 auto;
}


a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    color: #000;
}

/*
    Typography
 */

h1, h2, h3, h4, h5, h6,
.alpha, .beta, .gamma, .delta, .epsilon, .zeta {
    color: #111;
    margin-bottom: 1rem;
}

h1,
.alpha {
    font-size: 2em;
}

h2,
.beta {
    font-size: 1.5em;
}

h3,
.gamma {
    font-size: 1.125em;
}

h4,
.delta {
    font-size: 1em;
}

.sub-text {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    color: #999;
}


/*
    Vertical Rhythm
 */

p,
/*img,*/
ol,
ul,
dl {
    margin-bottom: 1.5rem;
}


/*
    Media Block
 */

.media {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 360px;

    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.media:hover {
    color: #fff;
}

    .media-image {
        display: block;
    }

        .media-image img {
            margin: 0;
        }

    .media-body {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;
        visibility: hidden;
        opacity: 0;

        background-color: rgba(255, 255, 255, 0.6);
        -webkit-transition: all ease .3s;
        color: #444;
    }

    .media:hover .media-body {
        /*display: block;*/
        visibility: visible;
        opacity: 1;
        cursor: pointer;
    }

        .v-center {
            display: table;
            width: 100%;
            height: 100%;
        }

        .v-center-inner {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            width: 100%;
            height: 100%;
        }



.box-title,
.box-subtitle {
    display: block;
}

.box-subtitle {
    font-size: 12px;
}

.box-credit {
    position: absolute;
    left: 5px;
    bottom: 5px;
    font-size: 10px;
    text-transform: capitalize;
}

.widget {
    margin-bottom: 3em;
}

.widget h4 {
    font-size: 16px;
    font-weight: bold;
}

.title-link {
    /*font-weight: bold;*/
    text-transform: uppercase;
    color: #000;
}

strong {
    font-weight: bold;
}

.logos {
    height: 70px;
    width: auto;
    margin: 0 10px 0 0;
    float: left;
}


.tweet-box {
    position: relative;
    padding: 1em;
    color: #fff;
    background-color: #000;
    margin-bottom: 3rem;
}

.tweet-box ul {
    margin-bottom: 0;
}

.tweet-box a,
.tweet-box a:hover {
    color: #fff;
}

.tweet-arrow {
    position: absolute;
    bottom: -12px;
    left: 60px;
    border-right: 19px solid transparent;
    border-left: 0px solid transparent;
    border-top: 12px solid #000;
}

.twitter-icon {
    position: absolute;
    color: #3d9ddd;
    bottom: -40px;
    left: 30px;
    font-size: 30px;
}


.about-list li {
    list-style: square;
    margin-bottom: .35em;
    color: #ddd;
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.about-list li:hover {
    color: #3d9ddd;
}

.about-list span {
    font-size: 1rem;
    color: #444;
}

#gallery .row {
    width: 800px;
    margin: 0 auto;
}
